import React, { useState, useEffect } from "react";
import NavigationMenu from "./NavigationMenu";
import { Link } from "react-router-dom";
import { websiteUrl } from "../UrlPaths";
import Navbar from "./Navbar";
import "./Navbar.css";
import Menu from "./NavigationMenu";

function PostPageHeader() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);
  return (
    <header className="header-wrapper clearfix">
      <div
        className="header  body-color6"
        id="header"
        style={{ background: "black" }}
      >
        <div className="container">
          <div className="mag-content">
            <div className="row">
              <div className="col-md-12">
                {/* <Navbar /> */}
                {/* <a
                  className="navbar-toggle collapsed"
                  id="nav-button"
                  href="#mobile-nav"
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </a> */}

                <nav className="navbar mega-menu">
                  {(toggleMenu || screenWidth > 500) && (
                    <>
                      <ul className="list">
                        <li className="items">
                          <Link
                            to={`/`}
                            className="logo"
                            title="logo"
                            rel="home"
                          >
                            <img
                              src={`${websiteUrl}img/LN-Sports-logo.png`}
                              width={150}
                              alt=""
                            />
                          </Link>
                        </li>
                        <Menu />
                      </ul>
                    </>
                  )}

                  <button onClick={toggleNav} className="navbar-toggle">
                    {toggleMenu == false ? (
                      <>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </>
                    ) : (
                      <>
                      {/* <i class="fa fa-times" aria-hidden="true"></i> */}
                        <span className="icon-bar"></span>
                      </>
                    )}
                  </button>

                  {/* <NavigationMenu /> */}

                  <div className="header-right">
                    <div className="social-icons">
                      <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Facebook"
                        target="_blank"
                      >
                        <i className="fa fa-facebook fa-lg"></i>
                      </a>
                      <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Twitter"
                        target="_blank"
                      >
                        <i className="fa fa-twitter fa-lg"></i>
                      </a>
                      {/* <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Google+"
                        target="_blank"
                      >
                        <i className="fa fa-google-plus fa-lg"></i>
                      </a> */}
 <a
                    href="https://www.youtube.com/@lnsportsnetwork3737"
                    data-toggle="tooltip"
                    target="_blank"
                    data-placement="bottom"
                    title="YouTube"
                  >
                    <i className="fa fa-youtube fa-lg"></i>
                  </a>
                      <a
                        href="#"
                        className="fixed-button navbar-toggle"
                        id="fixed-button"
                      >
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                      </a>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default PostPageHeader;
