import axios from "axios";
import { APIEndpoint } from "../UrlPaths";

export async function getHomeSection1Posts(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getHomeSection1Posts",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}


export async function getHomeSection2Posts(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getHomeEntertainmentSectionPosts",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getHomeSection3Posts(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getHomeVideoSectionPosts",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getHomeSection4Posts(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getHomeBusinessSectionPosts",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getHomeSection5Posts(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getHomeTechSectionPosts",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getHomeSection6Posts(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getHomeNewsSectionPosts",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getSideTabsPosts(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getSideTabsPosts",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getSinglePageContent(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getSinglePageContent",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getRandomPosts(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getRandomPosts",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}

export async function getCategoryPosts(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getCategoryPosts",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}


export async function increaseReadCount(data) {
  try {
    const response = await axios.post(APIEndpoint + "/addViewCount",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}