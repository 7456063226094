import React, { useState, useEffect } from "react";
// import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useParams } from "react-router-dom";
import { getSinglePageContent,increaseReadCount } from "../services/PostAPIServices";
import { postImageUrl } from "../UrlPaths";

import Footer from "../components/Footer";
import PostPageHeader from "../components/PostPageHeader";
import RelatedPost from "../components/RelatedPost";
import SideTabs from "../components/SideTabs";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import moment from "moment";
import { FacebookShareButton, TwitterShareButton, PinterestShareButton } from "react-share";
import HelmetMetaData from "../components/MetaData";

import $ from "jquery"
import { useTranslate } from '../translate';
function Post() {
  const { id } = useParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const preview = queryParameters.get("preview");

  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  let root_pathname = window.location.href;

  const [postTitle, setPostTitle] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [author, setAuthor] = useState("");
  const [postBody, setPostBody] = useState("");
  const [featuredImage, setFeaturedImage] = useState("");
  const [nextPostId, setNextPostId] = useState("");
  const [nextPostTitle, setNextPostTitle] = useState("");
  const [previousPostId, setPreviousPostId] = useState("");
  const [previousPostTitle, setPreviousPostTitle] = useState("");
  const [createdDate, setCreatedDate] = useState(new Date());
  const [authorImage, setAuthorImage] = useState("");
  const [viewsCount, setViewsCount] = useState("");
  const [short_content, setShortContent] = useState("");

  // const usePageMeta = (title, description) =>{
  //   const defaultTitle = "app-name";
  //   const defaultDesc = "meta description";
  
  //   useEffect(() => {
  //       document.title = title || defaultTitle;
  //       document.querySelector("meta[name='description']").setAttribute("content", description || defaultDesc);
  //   }, [defaultTitle, title, defaultDesc, description]);
  // }

  useEffect(() => {

    if(preview == null){
      const preview = false;
    }else{
     
    }

    var data = { id,language ,preview};
    document.querySelector("[name='description']").remove()
    getSinglePageContent(data).then((response) => {
      setPostTitle(response.post_content.title);
      setCategoryName(response.categoryName);
      setCategoryId(response.post_content.category_id);
      setAuthor(response.post_content.author);
      setPostBody(response.post_content.post_content);
      setFeaturedImage(response.post_content.main_image_path);
      setCreatedDate(response.post_content.created_at);
      setAuthorImage(response.post_content.profile_img_path);
      setViewsCount(response.post_content.read_count);
      setNextPostId(response.nextPost.id);
      setNextPostTitle(response.nextPost.title);
      setPreviousPostId(response.previousPost.id);
      setPreviousPostTitle(response.previousPost.title);
      setShortContent(response.post_content.short_content);
    });
    increaseReadCount(data).then((response) => {});
  }, []);
  function createMarkup() {
    return { __html: postBody };
  }

  function calculateTime(time) {
    TimeAgo.addLocale(en);
   
    const timeAgo = new TimeAgo("en-US");
    const inSeconds = new Date(time).getTime();
    const minutesAgo = timeAgo.format(inSeconds - 60 * 1000);

    return minutesAgo;
  }

  function getDate(time) {
    var date = moment(time).format("MMMM DD,YYYY");

    return date;
  }

  function stripHTML(myString) {
    let el = document.createElement("div");
    el.innerHTML = myString;
    return el.textContent || el.innerText || "";
 }

  return (

    <div id="main" className="header-style1">

       <HelmetMetaData title={postTitle+'| LN Sports Network'}
          description={ stripHTML(short_content)+ ' ...'}
          image={`${postImageUrl}${featuredImage}`}
      ></HelmetMetaData>

      <PostPageHeader />
      <div
        className="parallax-header"
        style={{
          backgroundImage: `url(${postImageUrl}${featuredImage})`,
          backgroundRepeat:'no-repeat',
          backgroundSize: "cover",
          width:'100%'
        }}
      >
        <div
          className="parallax-image"
          id="parallax-image"
          data-stellar-ratio="0.5"
          data-image={`${postImageUrl}${featuredImage}`}
        ></div>
        <div className="parallax-wrapper">
          <div className="container">
            <div className="mag-content parallax-box">
              <div className="row">
                <div className="col-md-12 parallax-box">
                  <header className="post-header">
                    <h1 className="post-title">{postTitle}</h1>
                    <Link
                      to={`/category/${categoryId}`}
                      className="category bgcolor3"
                    >
                      {categoryName}
                    </Link>
                  </header>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="main-content mag-content clearfix">
          <div className="row blog-content" data-stickyparent>
            <div className="col-md-8" data-stickycolumn>
              <article className="post-wrapper clearfix">
                <div className="post-content clearfix">
                  <p className="simple-share">
                    <span className="article-date">
                      <img
                        alt=""
                        src={`${postImageUrl}${authorImage}`}
                        height="32"
                        width="32"
                        loading="lazy"
                        decoding="async"
                      />
                      &nbsp;Published &nbsp;
                      <i className="fa fa-clock-o"></i>{" "}
                      {calculateTime(new Date(createdDate))} on{" "}
                      {getDate(new Date(createdDate))}
                    </span>
                    <span>
                      <i className="fa fa-eye"></i> {viewsCount} views
                    </span>
                    <br />
                    <span>
                      By{" "}
                      <a>
                        <b>{author}</b>
                      </a>
                    </span>
                  </p>
                  <div
                    className="ck-content"
                    dangerouslySetInnerHTML={createMarkup()}
                  />
                </div>

                <footer className="post-meta">
                  <div className="share-wrapper clearfix">
                    <div className="share-buttons">
                    <FacebookShareButton
                    url={root_pathname}
                    image={`${postImageUrl}${featuredImage}`}
                    quote={postTitle}
                    description={stripHTML(short_content)}
                    className="Demo__some-network__share-button"
                  >
                      <a className="social-share facebook"  >
                        <i className="fa fa-facebook"></i>
                        <span className="alt-text">Share</span>
                      </a>
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={root_pathname}
                    title={postTitle}
                    hashtag={"#"+categoryName}
                 
                  >
                    <a className="social-share twitter" >
                                        <i className="fa fa-twitter"></i>
                                        <span className="alt-text">Tweet</span>
                                      </a>
                  </TwitterShareButton>

                  <PinterestShareButton url={root_pathname} media={`${postImageUrl}${featuredImage}`} description={stripHTML(short_content)+ ' ...'}>
                  <a className="social-share pinterest" >
                        <i className="fa fa-pinterest"></i>
                      </a> 
                  </PinterestShareButton>
                      {/* <a className="social-share google-plus" href="#">
                        <i className="fa fa-google-plus"></i>
                      </a>
                      <a className="social-share linked_in" href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                      <a className="social-share pinterest" href="#">
                        <i className="fa fa-pinterest"></i>
                      </a> */}
                    </div>
                  </div>

                  <div className="row">
                    <div className="post-nav-wrapper clearfix">
                      <div className="col-md-6 omega">
                        <div className="previous-post">
                          <div className="post-nav-label">
                            <i className="fa fa-angle-left"></i>
                           &nbsp; {t('dont_miss_label')}
                          </div>
                          <Link
                            reloadDocument
                            to={`/post/${previousPostId}`}
                            className="post-nav-title"
                          >
                            {previousPostTitle}
                          </Link>
                        </div>
                      </div>

                      <div className="col-md-6 alpha">
                        <div className="next-post">
                          <div className="post-nav-label">
                          {t('up_next_label')} &nbsp;
                            <i className="fa fa-angle-right"></i>
                          </div>
                          <Link
                            reloadDocument
                            to={`/post/${nextPostId}`}
                            className="post-nav-title"
                          >
                            {nextPostTitle}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
              </article>
              <RelatedPost />
              {/* <div id="comments" className="comments-wrapper clearfix">
                <div id="respond" className="comment-form clearfix">
                  <h3 className="comment-title">Leave a Comment</h3>

                  <form
                    className="clearfix"
                    action="#"
                    method="post"
                    id="commentform"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="author"
                            id="author"
                            value=""
                            tabindex="1"
                            className="form-control"
                            placeholder="Name *"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            value=""
                            tabindex="2"
                            className="form-control"
                            placeholder="Email *"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            name="comment"
                            cols="50"
                            rows="6"
                            tabindex="4"
                            className="form-control"
                            placeholder="Your comment *"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          name="submit"
                          type="submit"
                          id="submit-button"
                          tabindex="5"
                          value="Submit"
                          className="btn btn-default"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div> */}
            </div>
            <div className="col-md-4" data-stickycolumn>
              <SideTabs />
            </div>
          </div>
        </div>
        <div className="mag-content clearfix">
          <div className="row">
            <div className="col-md-12">
              <div className="ad728-wrapper">
                {/* <a href="#">
                  <img src="http://placehold.it/728x90" alt="" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div
        id="go-top-button"
        className="fa fa-angle-up"
        title="Scroll To Top"
      ></div>
      <div className="mobile-overlay" id="mobile-overlay"></div>
    </div>
  );
}
export default Post;
