import React, { useState, useEffect } from "react";

function MobileMenu() {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  return (
    <nav id="mobile-nav" className="mm-menu mm-vertical mm-theme-dark mm-effect-slide mm-border-full mm-offcanvas mm-hassearch">
      <div>
        <ul>
          <li>
            <a href="#">Home</a>
          </li>
          {/* <?php for($i=0;$i<8;$i++){ ?> */}
          <li>
            <a href="#">Entertainment</a>
          </li>
          {/* <?php } ?>    */}
        </ul>
      </div>
    </nav>
  );
}

export default MobileMenu;
