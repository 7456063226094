import axios from "axios";
import { APIEndpoint } from "../UrlPaths";

export async function getLatestEvents() {
  try {
    const response = await axios.post(APIEndpoint + "/getLatestEvents");
    return response.data.data;
  } catch (error) {
    return [];
  }
}


export async function getAllActiveEvents() {
  try {
    const response = await axios.post(APIEndpoint + "/getAllActiveEvents");
    return response.data.data;
  } catch (error) {
    return [];
  }
}
