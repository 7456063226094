import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getHomeSection6Posts } from "../services/PostAPIServices";
import { postImageUrl } from "../UrlPaths";
import { Time } from "./Time";
import { useTranslate } from '../translate';
function HomeSection6() {
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  const [mainPost, setMainPost] = useState([]);
  const [subPosts, setSubPosts] = useState([]);
  const [recentPosts, setRecentosts] = useState([]);

  useEffect(() => {
    const data = {
      language
    };
    getHomeSection6Posts(data).then((response) => {
      setMainPost(response.mainPosts);
      setRecentosts(response.recentPosts);
      setSubPosts(response.subPosts);
    });
  }, []);

  function stripHTML(myString) {
    let el = document.createElement("div");
    el.innerHTML = myString;
    return el.textContent || el.innerText || "";
 }
  const renderMainPost = mainPost.map((item, index) => (
    <article className="news-block big-block">
      <Link to={`/post/${item.post_id}`} className="overlay-link">
        <figure className="image-overlay">
          <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
        </figure>
      </Link>
      <Link to={`/category/${item.category_id}`} className="category">
        {item.category_name}
      </Link>
      <header className="news-details">
        <h3 className="news-title">
          <Link to={`/post/${item.post_id}`}>{item.title}</Link>
        </h3>
        <p>
        {stripHTML(item.short_content)}...
        </p>
        <p className="simple-share">
        <Time time={new Date(item.created_at)}/>
        </p>
      </header>
    </article>
  ));

  const renderSubPosts = subPosts.map((item, index) => (
    <article className="simple-post simple-big clearfix">
      <div className="simple-thumb">
        <Link to={`/post/${item.post_id}`}>
          
          <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
        </Link>
      </div>
      <header>
        <p className="simple-share">
         <b> <Link to={`/category/${item.category_id}`}>
            {item.category_name} {" "}
          </Link></b>
         / <Time time={new Date(item.created_at)}/>
        </p>

        <h3>
          <Link to={`/post/${item.post_id}`}>{item.title}</Link>
        </h3>

        <p className="excerpt">
        {stripHTML(item.short_content)}...
        </p>
      </header>
    </article>
  ));

  const renderRecentPosts = recentPosts.map((item, index) => (
    <div className="widget-post clearfix">
      <div className="author-thumb">
        <Link to={`/post/${item.post_id}`}>
          <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
        </Link>
      </div>
      <div>
        <h3>
          <Link to={`/post/${item.post_id}`}>{item.title}</Link>
        </h3>
        <p className="simple-share">
        <Time time={new Date(item.created_at)}/>
        </p>
      </div>
    </div>
  ));
  return (
    <div className="row" data-stickyparent>
      <div className="col-md-8">
        <section className="admag-block">
          <h3 className="block-title">
            <span>{t('more_news')}</span>
          </h3>

          {renderMainPost}

          {renderSubPosts}
        </section>

        {/* <div className="load-more">
          <button type="button" className="btn btn-lg btn-block">
            Load more
          </button>
        </div> */}
      </div>
      <div className="col-md-4" data-stickycolumn>
        <aside className="sidebar">
          <div className="widget social-links">
            <h3 className="block-title">
              <span>{t('follow_us')}</span>
            </h3>
            <ul className="social-list">
              <li className="social-facebook">
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title=""
                  data-original-title="Facebook"
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li
                className="social-twitter"
                data-toggle="tooltip"
                data-placement="bottom"
                title=""
                data-original-title="Twitter"
              >
                <a href="#">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li className="social-youtube">
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title=""
                  data-original-title="Youtube"
                >
                  <i className="fa fa-youtube"></i>
                </a>
              </li>
              <li className="social-instagram">
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title=""
                  data-original-title="Instagram"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="widget">
            <h3 className="block-title">
              <span>{t('recent_posts')}</span>
            </h3>
            <div className="row">
              <div className="col-md-12">{renderRecentPosts}</div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
}
export default HomeSection6;
