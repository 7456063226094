import React, { useContext } from 'react';

import {BrowserRouter, Routes, Route  } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Home from "./pages/Home";
import Post from "./pages/Post";
import Category from "./pages/Category";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Event from "./pages/Event";
import EventView from "./pages/EventView";
import Test from "./pages/Test";
import { TranslateProvider } from './translate';
import Epaper from './pages/Epaper';

// const PageDataContext = React.createContext({});

// const PageDataProvider = ({ children }) => {
//   const [pageData, setPageData] = React.useState({
//     title: 'Default Title',
//     description: 'Default Description',
//     keywords: 'Default Keywords',
//   });
//   return (
//     <PageDataContext.Provider value={{ pageData, setPageData }}>
//       {children}
//     </PageDataContext.Provider>
//   );
// };

function App() {
  return (
    // <PageDataProvider>
  
    <TranslateProvider>
    <HelmetProvider>
    <BrowserRouter>
       <Routes>
       <Route path="/" element={<Home/>} />
       <Route path="/test" element={<Test/>} />
       <Route path={"/post/:id"} element={<Post/>} />
       <Route path={"/category/:id"} element={<Category/>} />
       <Route path="/sign-up" element={<SignUp/>} />
       <Route path="/login" element={<Login/>} />
       <Route path="/forgot-password" element={<ForgotPassword/>} />
       <Route path="/password/reset/:token/:mail" element={<ResetPassword />} />
       <Route path="/events" element={<Event/>} />
       <Route path="/event/:id" element={<EventView/>} />
       <Route path="/e-paper" element={<Epaper/>} />
      </Routes>
    </BrowserRouter>
    </HelmetProvider>
    </TranslateProvider>
    // </PageDataProvider>

  );
}

export default App;
