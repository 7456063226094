import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getLatestEvents} from "../services/EventAPIServices";
import { postImageUrl } from "../UrlPaths";
function Event() {
    const [eventLatest, setEventLatest] = useState([]);
    
  useEffect(() => {
    getLatestEvents().then((response) => {
      setEventLatest(response.records);
    });
  }, []);

    const renderLatestEvent = eventLatest.map((event, index) => (
        <div class="widget author-widget">
        <div class="author-thumb">
        <Link to={`/events`}>
            <img alt="" src={`${postImageUrl}${event.featured_image}`} class="avatar" />
          </Link>
        </div>
        <div class="author-meta">
          <h3 class="author-title">
          <Link to={`/events`} >{event.title}</Link>
          </h3>
          <p class="author-position">{event.start_date} {event.start_time}</p>
          {/* <p class="author-bio">TimeZone: {event.time_zone}</p> */}
          <div class="author-page-contact">
          <Link to={`/events`} >
              <button
                name="submit"
                type="submit"
                id="submit-button"
                value="Submit"
                className="btn btn-danger"
                style={{ borderRadius: "5px", margin: "10px" }}
              >
                See More Events
              </button>
            </Link>
          </div>
        </div>
      </div>
        ));
  return (<>
    {renderLatestEvent}
    </>
  );
}

export default Event;
