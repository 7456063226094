import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getHomeSection3Posts } from "../services/PostAPIServices";
import { postImageUrl } from "../UrlPaths";
import { Time } from "./Time";
import { useTranslate } from '../translate';
function HomeSection3() {

  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  const [mainPost, setMainPost] = useState([]);
  const [subPosts, setSubPosts] = useState([]);

  useEffect(() => {
    const data = {
      language
    };
    getHomeSection3Posts(data).then((response) => {
      setMainPost(response.mainPost);
      setSubPosts(response.subPosts);
    });
  }, []);

  const renderMainPosts = mainPost.map((item, index) => (
    <article className="featured-small box-news box-big">
      <Link to={`/post/${item.post_id}`}>
        <span className="play-button">
          <i className="fa fa-play"></i>
        </span>
        <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
      </Link>
      <header className="featured-header">
        <Link
          to={`/category/${item.category_id}`}
          className="category bgcolor2"
        >
          {item.category_name}
        </Link>
        <h2>
          <Link to={`/post/${item.post_id}`}>{item.title}</Link>
        </h2>
        <p className="simple-share">
          <Time time={new Date(item.created_at)} />
        </p>
      </header>
    </article>
  ));

  const renderSubPosts = subPosts.map((item, index) => (
    <article className="widget-post clearfix">
      <div className="simple-thumb">
        <Link to={`/post/${item.post_id}`}>
          <span className="play-button">
            <i className="fa fa-play"></i>
          </span>
          <img
            src={`${postImageUrl}${item.main_image_path}`}
            width="160"
            alt=""
          />
        </Link>
      </div>
      <header>
        <h3 style={{fontSize: '18px'}}>
          <Link to={`/post/${item.post_id}`}>{item.title}</Link>
        </h3>
        <p className="simple-share">
          <Time time={new Date(item.created_at)} />
        </p>
      </header>
    </article>
  ));

  return (
    <section className="admag-block">
      <div className="row">
        <div className="col-md-12">
          <h3 className="block-title">
            <span>{t('videos')}</span>
          </h3>
        </div>
      </div>

      <div className="row">
        <div className="col-md-7">{renderMainPosts}</div>

        <div className="col-md-5">{renderSubPosts}</div>
      </div>
    </section>
  );
}
export default HomeSection3;
