import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useParams } from "react-router-dom";
import { postImageUrl } from "../UrlPaths";

import { getAllActivePapers } from "../services/EpaperAPIServices";
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import SideTabs from "../components/SideTabs";
import { useTranslate } from '../translate';
// import { FlipBook } from "../components/FlipBook";
// import $ from "jquery"
import * as $ from 'jquery';
// import {FlipBook} from 'flip-book';
// import * as THREE from 'three';

function Epaper() {
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  const [top_papers, setTopEPapers] = useState([]);
  const [other_papers, setOtherList] = useState([]);

  // window.PDFJS_LOCALE = {
  //   pdfJsWorker: 'js/pdf.worker.js',
  //   pdfJsCMapUrl: 'cmaps'
  // };

  useEffect(() => {
    // $('#sample-container').FlipBook({
    //   pdf: 'https://lionnationsports.com/wp-content/uploads/2022/12/Lion-Nation-1.pdf',
    //   template: {"html":"templates\/default-book-view.html","styles":["css\/font-awesome.min.css","css\/short-black-book-view.css"],"script":"js\/default-book-view.js","sounds":{"startFlip":"sounds\/start-flip.mp3","endFlip":"sounds\/end-flip.mp3"}}  });
   
      // $('#sample-container').FlipBook({
    //   pdf: 'Kalidara.pdf',
    //   template: {"html":"templates\/default-book-view.html","styles":["css\/font-awesome.min.css","css\/short-black-book-view.css"],"script":"js\/default-book-view.js","sounds":{"startFlip":"sounds\/start-flip.mp3","endFlip":"sounds\/end-flip.mp3"}}  
    //   });
    
    //   $('#sample-container').FlipBook({
    // pdf: 'https://lionnationsports.com/wp-content/uploads/2022/12/Lion-Nation-1.pdf',
    // // template: {"html":"templates\/default-book-view.html","styles":["css\/font-awesome.min.css","css\/short-black-book-view.css"],"script":"js\/default-book-view.js","sounds":{"startFlip":"sounds\/start-flip.mp3","endFlip":"sounds\/end-flip.mp3"}}  
    // });
    
    // $('#sample-container').FlipBook({
    //   pdf: 'books/pdf/FoxitPdfSdk.pdf',
    //   template: {
    //     html: 'node_modules/flip-book/templates/default-book-view.html',
    //     links: [
    //       {
    //         rel: 'stylesheet',
    //         href: 'node_modules/flip-book/css/font-awesome.min.css'
    //       }
    //     ],
    //     styles: [
    //       'node_modules/flip-book/css/short-black-book-view.css'
    //     ],
    //     links: [{
    //       rel: 'stylesheet',
    //       href: 'node_modules/flip-book/css/font-awesome.min.css'
    //     }],
    //     script: 'node_modules/flip-book/js/default-book-view.js'
    //   }
    // });
    var data = { language};
    getAllActivePapers(data).then((response) => {
      setTopEPapers(response.top_papers);
      setOtherList(response.other_papers);
    });
  }, []);

  const renderOtherpaperList = other_papers.map((item, index) => (
    <li>
      <article className="small-article">
        <h3> Volume: {item.volume_no} - {item.paper_date}</h3>
        <figure className="feed-image">
        {/* <FlipBook pdf={`${postImageUrl}${item.pdf}`}  />   */}
        </figure>
      </article>
    </li>
  ));

  const renderTopPapers = top_papers.map((item, index) => (<>
    
    <article className="news-block">
   
        <figure className="image-overlay">
          <img src={`${postImageUrl}${item.pdf}`} alt="" />
        </figure>
     

      <div className="news-details" >
        <h3 className="news-title">
         Volume: {item.volume_no} - {item.paper_date}
        </h3>
      </div>
    </article>
    </>));

  return (
    <>
     <HelmetProvider>
      <div id="main" className="header-big">
      <Helmet>
      <title> {t('epaper_page_header')} |  {t('ln_sports')}</title>
      {/* <script src="flip-book/three.min.js"></script>
      <script src="flip-book/pdf.min.js"></script> */}

<script src="flip-book/three.min.js"></script>
      <script src="./flip-book/pdf.min.js"></script>

      {/* <script type="text/javascript"> window.PDFJS_LOCALE = { pdfJsWorker: 'flip-book/pdf.worker.js', pdfJsCMapUrl: 'cmaps'};</script> */}
      <script src="./flip-book/3dflipbook.min.js"></script>
      {/* <script src="flip-book/3dflipbook.min.js"></script> */}
      </Helmet>
        <MainHeader />

        <div className="container main-wrapper">
          <div className="main-content mag-content clearfix">
            <div className="row main-body" data-stickyparent>
              <div className="col-md-8">
              <h3 class="page-subtitle"> {t('epaper_page_header')}</h3>
                <section className="admag-block">
                  <div className="row">

             
                    {renderTopPapers}
                    <article className="news-block">
                    <div className="flip-book-container" id="sample-container" ></div>
                    </article>
                  </div>
                </section>
                <div className="row">{renderOtherpaperList}</div>
              </div>

              <div className="col-md-4" data-stickycolumn>
                <SideTabs />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <div
        id="go-top-button"
        class="fa fa-angle-up"
        title="Scroll To Top"
      ></div>
      <div class="mobile-overlay" id="mobile-overlay"></div>
      </HelmetProvider>
    </>
  );
}
export default Epaper;
