import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getMenuCategories } from "../services/CategoryAPIServices";
import { postImageUrl } from "../UrlPaths";
import {
  useTranslateState
} from '../translate';


function NavigationMenu() {
  const { lang } = useTranslateState(); // we get the current language
  const language = localStorage.getItem("rcml-lang");
  const [menus, setMenu] = useState([]);

  useEffect(() => {
    const data = {
      language
    };
    getMenuCategories(data).then((response) => {
  
      if(language == "sinhala"){
        setMenu(response.sin_categories);
      }else if(language == "tamil"){
        setMenu(response.tm_categories);
      }else{
        setMenu(response.eng_categories);
      }
    
    });
  }, []);

  const renderMenu = menus.map((item, index) => (
    <li className="dropdown mega-full menu-color3 items">
      <Link
        reloadDocument
        // className="dropdown-toggle"
        role="button"
        // aria-expanded="false"
        // data-toggle="dropdown"
        to={`/category/${item.id}`}
      >
        {item.category_name}
      </Link>
    </li>
  ));

  return (
    // <div className="navbar-collapse collapse ">
      <ul className="list">
        {renderMenu}
      </ul>
    // </div>
  );
}

export default NavigationMenu;
