import { Helmet, HelmetProvider } from 'react-helmet-async';
import Footer from "../components/Footer";
import HomeSection1 from "../components/HomeSection1";
import HomeSection2 from "../components/HomeSection2";
import HomeSection3 from "../components/HomeSection3";
import HomeSection4 from "../components/HomeSection4";
import HomeSection5 from "../components/HomeSection5";
import HomeSection6 from "../components/HomeSection6";
import MainHeader from "../components/MainHeader";
import MetaData from "../components/MetaData";
import MobileMenu from "../components/MobileMenu";

function Home() {
  return (
    <>
      <div id="main" class="header-big">
      <Helmet>
      <title>Home | LN Sports Network </title>
      <meta name="keywords" content="LN Sports Network"/>
      <meta
      name="description"
      content="LN Sports Network - Sri Lanka's first dedicated Website and the National Newspaper focusing on sports."/>
      <meta name="author" content="Archnix"/>
      </Helmet>
        <MainHeader />

        <div class="container main-wrapper">
          <div class="main-content mag-content clearfix">
            <HomeSection1 />
            <HomeSection2 />
            <HomeSection3 />
            <HomeSection4 />
            <HomeSection5 />
            <HomeSection6 />
          </div>
        </div>
        <Footer />
      </div>
      <MobileMenu/>
      <div
        id="go-top-button"
        class="fa fa-angle-up"
        title="Scroll To Top"
      ></div>
      <div class="mobile-overlay" id="mobile-overlay"></div>
    </>
  );
}
export default Home;
