import axios from "axios";
import { APIEndpoint } from "../UrlPaths";

export async function getMenuCategories(data) {
  try {
    const response = await axios.post(APIEndpoint + "/getMenuCategories",data);
    return response.data.data;
  } catch (error) {
    return [];
  }
}



