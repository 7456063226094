import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { DatePicker } from "antd";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import { OTPsend, storeCustomer } from "../services/AuthAPIServices";
import "antd/dist/antd.css";
import Swal from "sweetalert2";
import $ from "jquery";
import { useTranslate } from '../translate';

function SignUp() {
  const homeNavigate = useNavigate();
  const i18n = useTranslate();
  const { t } = i18n;

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [mobile_no, setMobileNo] = useState("");
  const [email, setOfsEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [confirmedPwd, setConfirmedPwd] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [otpCode, setOTP] = useState("");
  const [otpSection, setOtpSection] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [password, setPasswordInput] = useState("");
  const handlePasswordChange =(evnt)=>{
        setPasswordInput(evnt.target.value);
    }
    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }

  useEffect(() => {
    uiBlockControl();
  }, [otpSection]);

  const handleSubmitUserData = (e) => {
    e.preventDefault();
    const data = {
      first_name,
      last_name,
      dob,
      mobile_no,
      password,
      email,
    };
    if (password.length < 8) {
      Swal.fire(
        "Warning",
        "Password must be at least 8 characters...!",
        "warning"
      );
    } else if (password != confirmedPwd) {
      Swal.fire("Warning", "Passwords do not match..!", "warning");
    } else {
      setButtonLoading(true);
      OTPsend(data).then((response) => {
        console.log(response);

        if (response.status_code == 1) {
          setOtpSection(true);
          Swal.fire("Success", "" + response.message, "success");
          uiBlockControl();
        } else {
          setButtonLoading(false);
          Swal.fire("Warning", " " + response, "warning");
        }
      });
    }
  };

  const handleSubmitAllData = (e) => {
    e.preventDefault();
    const data = {
      first_name,
      last_name,
      dob,
      mobile_no,
      password,
      email,
      otpCode,
    };
    if (otpCode == "" || otpCode == null) {
      Swal.fire("Warning", "Please fill the OTP number", "warning");
    } else {
      setButtonLoading(true);
      storeCustomer(data).then((response) => {
        if (response.status_code == 1) {
          Swal.fire("Success", "" + response.message, "success");
          uiBlockControl();
          setButtonLoading(false);
          setFirstName("");
          setLastName("");
          setDob("");
          setMobileNo("");
          sessionStorage.setItem("user", response.name);
          sessionStorage.setItem("user_id", response.user_id);
          sessionStorage.setItem("authenticated", true);
          sessionStorage.setItem("access_token", response.access_token);
          homeNavigate("/");
          homeNavigate(0);
        } else {
          setButtonLoading(false);
          Swal.fire("Warning", " " + response.message, "warning");
        }
      });
    }
  };

  function uiBlockControl() {
    console.log(buttonLoading);
    if (otpSection == false) {
      $("#registerForm").show();
      $("#otpSection").hide();
    } else {
      $("#registerForm").hide();
      $("#otpSection").show();
    }
  }

  return (
    <>
      <div id="main" class="header-big">
      <Helmet>
      <title>{t('sign_up_page_title')}</title>
      </Helmet>
        <MainHeader />

        <div class="container main-wrapper">
          <div class="main-content mag-content clearfix">
            <div id="registerForm" className="registerForm-wrapper clearfix">
              <div id="respond" className="comment-form clearfix">
                <h3
                  className="comment-title text-center"
                  style={{ marginTop: 50 }}
                >
                  {t('register_form_header')}
                </h3>

                <form
                  onSubmit={(e) => {
                    handleSubmitUserData(e);
                  }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <label for="form-action-3">
                        {t('first_name_label')} <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          id="first_name"
                          className="form-control"
                          placeholder="First Name"
                          name="first_name"
                          value={first_name}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label for="form-action-3">
                      {t('last_name_label')} <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          id="last_name"
                          className="form-control"
                          placeholder="Last Name"
                          name="last_name"
                          value={last_name}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label for="form-action-3"> {t('dob_label')}</label>
                      <div className="form-group">
                        <DatePicker
                          className="form-control"
                          placeholder="Date of Birth"
                          format="YYYY-MM-DD"
                          width="100%"
                          selected={dob}
                          value={dob}
                          onChange={(date) => setDob(date)}
                          disabledDate={(current) => {
                            return moment().subtract(10, "years") <= current;
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label for="form-action-3">  {t('mobile_no_label')} </label>
                      <div className="form-group">
                        <input
                          type="text"
                          id="mobile_no"
                          className="form-control"
                          placeholder="Mobile No "
                          name="mobile_no"
                          value={mobile_no}
                          onChange={(e) => setMobileNo(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <h4>
                        <b> {t('credentials_header_label')}</b>
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label for="form-action-3">
                      {t('email_label')} <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          id="ofs_email"
                          className="form-control"
                          placeholder="Email"
                          name="ofs_email"
                          value={email}
                          onChange={(e) => setOfsEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label for="form-action-3">
                      {t('password_label')} <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="form-group">
                      <div className="input-group my-4 mx-4">
                        <input
                          type={passwordType}
                          id="password"
                          className="form-control"
                          placeholder="Password"
                          name="password"
                          value={password}
                          onChange={handlePasswordChange} 
                          // onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                         <div className="input-group-btn">
                     <button type="button" className="btn btn-outline-primary" onClick={togglePassword}>
                     { passwordType==="password" ? <i className="fa fa-eye-slash"></i> :<i className="fa fa-eye"></i> }
                     </button>
                    </div>
                      </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label for="form-action-3">
                      {t('confirm_pwd_label')} <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="form-group">
                        <input
                          type="password"
                          id="confirmed_pwd"
                          className="form-control"
                          placeholder="Confirmed Password"
                          name="confirmed_pwd"
                          value={confirmedPwd}
                          onChange={(e) => setConfirmedPwd(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <input type="checkbox" required />
                      &nbsp;
                      <label>
                        <Link to={`#`}>
                          <span
                            style={{
                              color: "#79829A",
                              textDecoration: "underline blue",
                            }}
                          >
                            {" "}
                            {t('tc_label')}
                          </span>
                        </Link>
                      </label>
                    </div>
                  </div>
                  <div className="row text-right">
                    <div className="col-md-12">
                      <button
                        name="submit"
                        type="submit"
                        id="submit-button"
                        // disabled={buttonLoading}
                        value="Submit"
                        className="btn btn-primary"
                      >
                        {buttonLoading == true ? (
                          <>
                            <i className="ft-refresh-ccw"> </i>
                            <span> {t('submitting')}...</span>
                          </>
                        ) : (
                          <>
                            <i className="ft-check-square mr-1"></i>
                            <span>  {t('register_button')}</span>{" "}
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <center>
              <div className="row main-body text-center" id="otpSection">
                <div className="col-md-12">
                  <section className="admag-block text-center">
                    <form
                      onSubmit={(e) => {
                        handleSubmitAllData(e);
                      }}
                    >
                      <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                          <h6>
                            {" "}
                            {t('otp_sent_label')}
                          </h6>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-12">
                          <h4>{email}</h4>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                          <label for="form-action-3">
                          {t('enter_otp')} <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="form-group">
                            <input
                              type="password"
                              id="otpCode"
                              className="form-control"
                              placeholder="OTP *"
                              name="otpCode"
                              value={otpCode}
                              onChange={(e) => setOTP(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-12">
                          <button
                            name="submit"
                            type="submit"
                            id="submit-button"
                            value="Proceed"
                            className="btn btn-primary"
                          >
                             {t('proceed_button')}
                          </button>
                        </div>
                      </div>
                    </form>
                  </section>
                </div>
              </div>
            </center>
          </div>
        </div>
        <Footer />
      </div>

      <div
        id="go-top-button"
        class="fa fa-angle-up"
        title="Scroll To Top"
      ></div>
      <div class="mobile-overlay" id="mobile-overlay"></div>
    </>
  );
}
export default SignUp;
