import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { postImageUrl } from "../UrlPaths";

import { getCategoryPosts } from "../services/PostAPIServices";
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import SideTabs from "../components/SideTabs";
import { Time } from "../components/Time";

function EventView() {
  const { id } = useParams();
  const [mainPost, setMainPost] = useState([]);
  const [sidePosts, setSidePosts] = useState([]);
  const [lastPosts, setLastPosts] = useState([]);
  const [categoryName, setCategoryName] = useState([]);

  useEffect(() => {
    var data = { id };
    getCategoryPosts(data).then((response) => {
      setMainPost(response.mainPosts);
      setSidePosts(response.sidePosts);
      setLastPosts(response.otherPosts);
      setCategoryName(response.categoryName);
    });
  }, []);
  function stripHTML(myString) {
    let el = document.createElement("div");
    el.innerHTML = myString;
    return el.textContent || el.innerText || "";
  }
  const renderLeftSidePosts = sidePosts.map((item, index) => (
    <li>
      <article className="small-article">
        <figure className="feed-image">
          <Link to={`/post/${item.id}`}>
            <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
          </Link>
        </figure>
        <h3>
          <Link to={`/post/${item.id}`}>{item.title} </Link>
        </h3>
        <p>
          <Time time={new Date(item.created_at)} />
        </p>
      </article>
    </li>
  ));

  const renderLatestPosts = mainPost.map((post, index) => (
    <article className="news-block">
      <Link to={`/post/${post.id}`} className="overlay-link">
        <figure className="image-overlay">
          <img src={`${postImageUrl}${post.main_image_path}`} alt="" />
        </figure>
      </Link>
      <Link to={`/category/${post.category_id}`} className="category">
        {post.category_name}
      </Link>

      <div className="news-details" >
        <h3 className="news-title">
          <Link to={`/post/${post.id}`}>{post.title}</Link>
        </h3>
        {index == 0 ? (
          <>
            {" "}
            <p>{stripHTML(post.short_content)}...</p>
          </>
        ) : (
          <></>
        )}

        <p className="simple-share">
          <Time time={new Date(post.created_at)} />
        </p>
      </div>
    </article>
  ));

  const renderLastPosts = lastPosts.map((item, index) => (
    <div className="col-md-6">
      <article className="news-block small-block">
        <Link reloadDocument to={`/post/${item.id}`} className="overlay-link">
          <figure className="image-overlay">
            <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
          </figure>
        </Link>

        <header className="news-details" style={{background:'white' }}>
          <Time time={new Date(item.created_at)} style={{color:'#a9a9a9'}} />
          <h4><b>
            <Link
              reloadDocument
              to={`/post/${item.id}`}
              style={{ color: "black" }}
            >
              {item.title}
            </Link>
            </b> </h4>
          {stripHTML(item.short_content)}...
          <p className="simple-share" >
            <br />
          </p>
        </header>
      </article>
    </div>
  ));
  return (
    <>
      <div id="main" class="header-big">
        <MainHeader />

        <div class="container main-wrapper">
          <div class="main-content mag-content clearfix">
            <div className="row main-body" data-stickyparent>
              <div className="col-md-8">
                <section className="admag-block">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="news-feed">
                        <h3 className="block-title">
                          <span>{categoryName}</span>
                        </h3>
                        <ul className="widget-content">
                          {renderLeftSidePosts}
                        </ul>
                      </div>
                    </div>

                    <div className="col-md-8">{renderLatestPosts}</div>
                  </div>
                </section>
                <div className="row">{renderLastPosts}</div>
              </div>

              <div className="col-md-4" data-stickycolumn>
                <SideTabs />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <div
        id="go-top-button"
        class="fa fa-angle-up"
        title="Scroll To Top"
      ></div>
      <div class="mobile-overlay" id="mobile-overlay"></div>
    </>
  );
}
export default EventView;
