import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getHomeSection5Posts } from "../services/PostAPIServices";
import { postImageUrl } from "../UrlPaths";
import { Time } from "./Time";
import { useTranslate } from '../translate';
function HomeSection5() {
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  const [mainPost, setMainPost] = useState([]);
  const [subPosts, setSubPosts] = useState([]);

  useEffect(() => {
    const data = {
      language
    };
    getHomeSection5Posts(data).then((response) => {
      setMainPost(response.mainPosts);
      setSubPosts(response.subPosts);
    });
  }, []);

  function stripHTML(myString) {
    let el = document.createElement("div");
    el.innerHTML = myString;
    return el.textContent || el.innerText || "";
 }
 
  const renderMainPosts = mainPost.map((item, index) => (
    <div className="col-md-6">
      <article className="news-block big-block">
        <Link to={`/post/${item.post_id}`} className="overlay-link">
          <figure className="image-overlay">
            <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
          </figure>
        </Link>
        <Link to={`/category/${item.category_id}`} className="category">
          {item.category_name}
        </Link>

        <header className="news-details" style={{background: "-webkit-gradient(linear, 50% 0%, 50%  15%, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.99)))"}}>
          <h4 className="news-title">
            <Link to={`/post/${item.post_id}`}>{item.title}</Link>
          </h4>
        
          <p className="simple-share">
          <Time time={new Date(item.created_at)}/>
          </p>
        </header>
      </article>
    </div>
  ));
  const renderSubPosts = subPosts.map((item, index) => (
    <div className="col-md-3" >
      <article className="news-block small-block"  >
        <Link to={`/post/${item.post_id}`} className="overlay-link">
          <figure className="image-overlay">
            <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
          </figure>
        </Link>
        <Link to={`/category/${item.category_id}`} className="category bgcolor4">
          {item.category_name}
        </Link>
        <header className="news-details" style={{background:'white'}}>
          <h3 className="news-title">
            <Link to={`/post/${item.post_id}`}  style={{color:'#222',fontSize: '20px',fontWeight: '700',letterSpacing: '-.02em'}}>{item.title}</Link>
          </h3>
          <p className="simple-share">
          <Time time={new Date(item.created_at)}/>
          </p>
        </header>
      </article>
    </div>
  ));
  return (
    <div className="row">
      <section>
        <div className="col-md-12">
          <div className="admag-block">
            <h3 className="block-title">
              <span>{t('tech')}</span>
            </h3>
            <div className="row">{renderMainPosts}</div>

            <div className="row">{renderSubPosts}</div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default HomeSection5;
