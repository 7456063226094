import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logoutAPI } from "../services/AuthAPIServices";
// import { Navbar, Nav, Container } from 'react-bootstrap';
import MobileMenu from "./MobileMenu";
import Menu from "./NavigationMenu";
import { websiteUrl } from "../UrlPaths";
import Swal from "sweetalert2";
// import {
//   Nav,
//   NavLink,
//   Bars,
//   NavMenu,
//   NavBtn,
//   NavBtnLink,
// } from './NavbarElements';
import Navbar from './Navbar';
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslate } from '../translate';


function MainHeader() {
  const i18n = useTranslate();
  const { t } = i18n;

  let navigate2 = useNavigate();
  const userName = sessionStorage.getItem("user");
  const [loggedUser, setLoggedUser] = useState(userName);
  const [isNavExpanded, setIsNavExpanded] = useState(false)

  const logoutEvent = (e) => {
    Swal.fire({
      title: "Are you sure logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        logoutAPI().then((response) => {
          if (response.status_code == "1") {
            sessionStorage.removeItem("access_token");
            sessionStorage.setItem("authenticated", false);
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("img_path");
            sessionStorage.removeItem("user_id");

            navigate2("/");
            navigate2(0);
          }
        });
      }
    });
  };
  
  return (<>
    <header className="header-wrapper clearfix">
      <div className="header-top">
        <div className="container">
          <div className="mag-content">
            <div className="row">
              <div className="col-md-12">
                <ul className="top-menu pull-right">
                  {loggedUser == "" || loggedUser == null ? (
                    <>
                      <li>
                        <Link to={`/sign-up`}>{t('sign_up')}</Link>
                      </li>
                      <li>
                        <Link to={`/login`}>{t('login')}</Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <span>Hello, {loggedUser}</span>
                      <span>
                        {" "}
                        |{" "}
                        <button
                          style={{
                            background: "none",
                            border: "none",
                            color: "white",
                          }}
                          type="button"
                          onClick={(e) => {
                            logoutEvent();
                          }}
                        >
                          <i className="fa fa-power-off"></i>
                        </button>
                      </span>
                    </>
                  )}
                    {/* <LanguageSelector /> */}
                </ul>

                <div className="social-icons pull-left">
                  <a
                    href="#"
                    data-toggle="tooltip"
                    target="_blank"
                    data-placement="bottom"
                    title="Facebook"
                  >
                    <i className="fa fa-facebook fa-lg"></i>
                  </a>
                  <a
                    href="#"
                    data-toggle="tooltip"
                    target="_blank"
                    data-placement="bottom"
                    title="Twitter"
                  >
                    <i className="fa fa-twitter fa-lg"></i>
                  </a>
                  <a
                    href="#"
                    data-toggle="tooltip"
                    target="_blank"
                    data-placement="bottom"
                    title="Instagram"
                  >
                    <i className="fa fa-instagram fa-lg"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/@lnsportsnetwork3737"
                    data-toggle="tooltip"
                    target="_blank"
                    data-placement="bottom"
                    title="YouTube"
                  >
                    <i className="fa fa-youtube fa-lg"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header-mid body-color6" style={{ background: "black" }}>
        <div className="container">
          <div className="mag-content">
            <div className="row">
              <div className="col-md-12">
                <div className="header-mid-wrapper text-center clearfix">
                  <div className="mid-logo-wrapper">
               
                    <a className="logo" href="/" title="logo" rel="home">
                      <img
                        src={`${websiteUrl}img/LN-Sports-logo.png`}
                        width="750"
                        alt=""
                      />
                    </a>
                    <div style={{textAlign: 'end', paddingRight: 0}}>
                    <LanguageSwitcher></LanguageSwitcher>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header" id="header">
        <div className="container">
          <div className="mag-content">
            <div className="row">
              <div className="col-md-12">
              <Navbar />

                {/* <Navbar.Collapse class="navbar-toggle collapsed" id="responsive-navbar-nav"> */}
                
                {/* <a class="navbar-toggle collapsed" id="nav-button" href="#mobile-nav">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </a>
                <nav className="navbar mega-menu">
                  <a className="logo" href="/" title="logo" rel="home"></a>

                 <Menu/>

                  <div className="header-right">
                    <div className="social-icons">
                      <a
                        href="#"
                        className="fixed-button navbar-toggle"
                        id="fixed-button"
                      >
                        <i></i>
                        <i></i>
                        <i></i>
                        <i></i>
                      </a>
                    </div>
                  </div>
                </nav> */}
              
                {/* </Navbar.Collapse> */}
                {/* <div id="sb-search" className="sb-search">
                  <form>
                    <input
                      className="sb-search-input"
                      placeholder="Enter your search text..."
                      type="text"
                      value=""
                      name="search"
                      id="search"
                    />
                    <input
                      className="sb-search-submit"
                      type="submit"
                      value=""
                    />
                    <span
                      className="sb-icon-search fa fa-search"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Search"
                    ></span>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    </> );
}

export default MainHeader;
