import { Link } from "react-router-dom";
import {websiteUrl } from "../UrlPaths";
import { useTranslate } from '../translate';
function Footer() {
  const i18n = useTranslate();
  const { t } = i18n;

  return (
    <footer
      className="footer source-org vcard copyright clearfix"
      id="footer"
      role="contentinfo"
    >
      <div className="footer-main" style={{ background: "black" }}>
        <div className="fixed-main">
          <div className="container">
            <div className="mag-content">
              <div className="row">
              <div className="col-md-5"></div>
                <div className="col-md-7 center-block">
                  
                    <div className="footer-block clearfix ">
                      <center>
                      <p className="clearfix" >
                        <Link
                          to={`/`}
                          className="logo"
                          title="LN Sports"
                          rel="home"
                        >
                          <img
                            src={`${websiteUrl}img/LN-Sports.png`}
                            alt=""
                          />
                        </Link>
                      </p>
              
                      {/* <ul
                        className="social-list clearfix"
                        style={{ marginLeft: "auto", marginRight: "auto" }}
                      >
                        <li className="social-facebook">
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title=""
                            data-original-title="Facebook"
                          >
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li
                          className="social-twitter"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title=""
                          data-original-title="Twitter"
                        >
                          <a href="#">
                            <i className="fa fa-twitter"></i>
                          </a>
                        </li>
                        <li className="social-youtube">
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title=""
                            data-original-title="Youtube"
                          >
                            <i className="fa fa-youtube"></i>
                          </a>
                        </li>
                        <li className="social-instagram">
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title=""
                            data-original-title="Instagram"
                          >
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                        <li className="social-rss">
                          <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title=""
                            data-original-title="RSS"
                          >
                            <i className="fa fa-rss"></i>
                          </a>
                        </li>
                      </ul> */}
                      </center>

                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom clearfix">
        <div className="fixed-main">
          <div className="container">
            <div className="mag-content">
              <div className="row">
                <div className="col-md-12 text-center">
                  <p>{t('copyright')} © 2023 {t('ln_sports')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
