import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getRandomPosts } from "../services/PostAPIServices";
import { postImageUrl } from "../UrlPaths";
import { useTranslate } from '../translate';

function RelatedPost(categoryId) {
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const data = {
      language
    };
    getRandomPosts(data).then((response) => {
      setPosts(response.posts);
    });
  }, []);

  const renderPosts = posts.map((item, index) => (
    <div className="col-md-4">
      <article className="news-block small-block post-article">
        <Link reloadDocument to={`/post/${item.id}`} className="overlay-link">
          <figure className="image-overlay">
            <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
          </figure>
        </Link>

        <header className="news-details" style={{background:'white'}}>
          <h5><b>
            <Link
              reloadDocument
              to={`/post/${item.id}`}
              style={{ color: "black" }}
            >
              {item.title}
            </Link></b>
          </h5>
          <p className="simple-share"></p>
        </header>
      </article>
    </div>
  ));

  return (
    <div className="related-posts clearfix">
      <h3 className="block-title">
        <span>{t('may_you_like_label')}</span>
      </h3>
      <div className="row">{renderPosts}</div>
    </div>
  );
}
export default RelatedPost;
