import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import { resetPasswordAPI } from "../services/AuthAPIServices";
import "antd/dist/antd.css";
import Swal from "sweetalert2";
import $ from "jquery";

function ResetPassword() {
  const { token, mail } = useParams();
  let resetNavigate = useNavigate();

  const [email, setEmail] = useState(mail);
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmed] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {}, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      email == "" ||
      email == null ||
      password == "" ||
      password == null ||
      password != password_confirmation ||
      password.length < 8
    ) {
      if (email == "" || email == null || password == "" || password == null) {
        Swal.fire("Warning", "Please fill the requried data.", "warning");
      } else if (password.length < 8) {
        Swal.fire(
          "Warning",
          "Password must be at least 8 characters...!",
          "warning"
        );
      } else if (password != password_confirmation) {
        Swal.fire("Warning", "Passwords do not match..!", "warning");
      }
    } else {
      const reset_data = {
        token,
        email,
        password,
        password_confirmation,
      };
      resetPasswordAPI(reset_data).then((res) => {
        console.log(res);
        if (res.status_code == 1) {
          Swal.fire("Success", "" + res.message, "success");
          sessionStorage.setItem("user", res.data.name);
          sessionStorage.setItem("user_id", res.data.user_id);
          sessionStorage.setItem("authenticated", true);
          sessionStorage.setItem("access_token", res.data.access_token);
          resetNavigate("/");
          resetNavigate(0);
        } else {
          Swal.fire("Warning", "" + res.message, "warning");
        }
      });
    }
  };
  return (
    <>
      <div id="main" class="header-big">
        <MainHeader />

        <div className="container main-wrapper">
          <div className="main-content mag-content clearfix">
            <article className="post-wrapper">
              <div className="post-content clearfix">
                <h3
                  className="comment-title text-center"
                  style={{ marginTop: 20 }}
                >
                  Reset your password
                </h3>

                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <div className="row">
                    <div className="col-md-4"> </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4"> </div>
                    <div className="col-md-4">
                      <label for="form-action-3">
                        New Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="form-group  d-flex justify-content-center">
                        <input
                          type="password"
                          id="password"
                          className="form-control"
                          placeholder="New Password"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4"> </div>
                    <div className="col-md-4">
                      <label for="form-action-3">
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="form-group  d-flex justify-content-center">
                        <input
                          type="password"
                          class="form-control mb-2"
                          placeholder="Confirm Password"
                          value={password_confirmation}
                          onChange={(e) => setPasswordConfirmed(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row text-center">
                    <div className="col-md-12">
                      <button
                        name="submit"
                        type="submit"
                        id="submit-button"
                        disabled={buttonLoading}
                        value="Submit"
                        className="btn btn-danger"
                      >
                        {buttonLoading == true ? (
                          <>
                            <i className="ft-refresh-ccw"> </i>
                            <span>Submitting...</span>
                          </>
                        ) : (
                          <>
                            <i className="ft-check-square mr-1"></i>
                            <span> Reset</span>{" "}
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </article>
          </div>
        </div>
        <Footer />
      </div>

      <div
        id="go-top-button"
        class="fa fa-angle-up"
        title="Scroll To Top"
      ></div>
      <div class="mobile-overlay" id="mobile-overlay"></div>
    </>
  );
}
export default ResetPassword;
