import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getHomeSection1Posts } from "../services/PostAPIServices";
import { postImageUrl } from "../UrlPaths";
import Event from "./Event";
import SideTabs from "./SideTabs";
import { Time } from "./Time";
import { useTranslate } from '../translate';

function HomeComponent1() {
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  const [trendingPosts, setTrending] = useState([]);
  const [midLatest, setMidLatest] = useState([]);

  useEffect(() => {
    const data = {
      language
    };
    getHomeSection1Posts(data).then((response) => {
      setTrending(response.trending_top_5);
      setMidLatest(response.latest_top_3);
    });
  }, []);

  function stripHTML(myString) {
    let el = document.createElement("div");
    el.innerHTML = myString;
    return el.textContent || el.innerText || "";
  }
  const renderTrendingPosts = trendingPosts.map((item, index) => (
    <li>
      <article className="small-article">
        <figure className="feed-image">
          <Link to={`/post/${item.post_id}`}>
            <img src={`${postImageUrl}${item.main_image_path}`} alt="" />
          </Link>
        </figure>
        <h3>
          <Link to={`/post/${item.post_id}`}>{item.title} </Link>
        </h3>
        <p className="simple-share">
       <b><Link to={`/category/${item.category_id}`}>
            {item.category_name} {" "}
          </Link></b> 
         / <Time time={new Date(item.created_at)} />
        </p>
      </article>
    </li>
  ));

  const renderLatestPosts = midLatest.map((post, index) => (
    <article className="news-block big-block">
      <Link to={`/post/${post.post_id}`} className="overlay-link">
        <figure className="image-overlay">
          <img src={`${postImageUrl}${post.main_image_path}`} alt="" />
        </figure>
      </Link>
      <Link to={`/category/${post.category_id}`} className="category bgcolor3">
        {post.category_name}
      </Link>

      <div className="news-details" style={{background: "-webkit-gradient(linear, 50% 0%, 50%  15%, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.99)))"}}>
        <h3 className="news-title">
          <Link to={`/post/${post.post_id}`}>{post.title}</Link>
        </h3>
        {index == 0 ? (
          <>
            {" "}
            <p>{stripHTML(post.short_content)}...</p>
          </>
        ) : (
          <></>
        )}

        <p className="simple-share">
          <Time time={new Date(post.created_at)} />
        </p>
      </div>
    </article>
  ));

  return (
    <div className="row main-body" data-stickyparent>
      <div className="col-md-8">
        <section className="admag-block">
          <div className="row">
            <div className="col-md-4">
              <div className="news-feed">
                <h3 className="block-title">
                  <span>{t('trending')}</span>
                </h3>
                <ul className="widget-content">{renderTrendingPosts}</ul>
              </div>
            </div>

            <div className="col-md-8">{renderLatestPosts}</div>
          </div>
        </section>
      </div>

      <div className="col-md-4" data-stickycolumn>
        <Event/>
        <SideTabs />
      </div>
    </div>
  );
}
export default HomeComponent1;
