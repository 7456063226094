import React, { useState, useEffect } from "react";
import "./Navbar.css";
import Menu from "./NavigationMenu";
import { Link } from "react-router-dom";
import { useTranslate } from '../translate';
import { websiteUrl } from "../UrlPaths";

export default function Navbar() {
  const i18n = useTranslate();
  const { t } = i18n;
  const language = localStorage.getItem("rcml-lang");
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <nav className="navbar mega-menu">
      {(toggleMenu || screenWidth > 500) && (
        <>
          <ul className="list">
            <li className="items">
              <Link
                to={`/`}
                className="dropdown-toggle"
                role="button"
                aria-expanded="false"
              >
                  {t('home')}
              </Link>
            </li>

            <Menu />
          </ul>
        </>
      )}

      <button onClick={toggleNav} className="navbar-toggle">
        {toggleMenu == false ? (
          <>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </>
        ) : (
          <>
         
            <span className="icon-bar"></span>
          </>
        )}
      </button>
      <div className="header-right">
            <div className="social-icons">
           <b> <a href={`${websiteUrl}ePaper.html?lang=${language}`} role="button" target="_blank" style={{color: '#1890ff',fontSize: '18px',fontFamily: "Oswald", marginRight:'30px'}}>  {t('epaper_nav_label')}</a></b>
            {/* <Link
            reloadDocument
            role="button"
            to={`/e-paper`}
            style={{color:'black',fontSize: '15px',fontWeight:'bold'}}
            >
            E-PAPER
            </Link> */}
            </div>
      </div>
    </nav>
  );
}
