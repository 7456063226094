import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import { resetMailSendingAPI } from "../services/AuthAPIServices";
import "antd/dist/antd.css";
import Swal from "sweetalert2";
import $ from "jquery";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {}, []);

  const handleSubmitAllData = (e) => {
    e.preventDefault();
    const data = {
      email,
    };

    setButtonLoading(true);
    resetMailSendingAPI(data).then((response) => {
      if (response.status_code == 1) {
        Swal.fire("Success", "" + response.message, "success");
      } else {
        Swal.fire("warning", " " + response.message, "warning");
      }
      setButtonLoading(false);
    });
  };

  return (
    <>
      <div id="main" class="header-big">
        <MainHeader />

        <div className="container main-wrapper">
          <div className="main-content mag-content clearfix">
            <article className="post-wrapper">
              <div className="post-content clearfix">
                <h3 className="comment-title text-center"   style={{ marginTop: 20 }}>Recover Password</h3>
                <p className="author-bio text-center">
                  Please enter your email address and we'll send you
                  instructions on how to reset your password.
                </p>
                <form
                  onSubmit={(e) => {
                    handleSubmitAllData(e);
                  }}
                >
                  <div className="row">
                  <div class="col-md-4">

                </div>
                    <div className="col-md-4">
                      
                      <div className="form-group">
                        <input
                          type="text"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row text-center">
                    <div className="col-md-12">
                    
                    </div>
                  </div>
                  <div className="row text-center">
                    <div className="col-md-12">
                    <label>
                        <Link to={`/login`}>
                          <span> <i className="fa fa-angle-left"></i> Back To Login</span>
                        </Link>
                      </label>
                      &nbsp;&nbsp;
                      <button
                        name="submit"
                        type="submit"
                        id="submit-button"
                        disabled={buttonLoading}
                        value="Submit"
                        className="btn btn-danger"
                      >
                        {buttonLoading == true ? (
                          <>
                            <i className="ft-refresh-ccw"> </i>
                            <span>Submitting...</span>
                          </>
                        ) : (
                          <>
                            <i className="ft-check-square mr-1"></i>
                            <span> Recover</span>{" "}
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </article>
          </div>
        </div>
        <Footer />
      </div>

      <div
        id="go-top-button"
        class="fa fa-angle-up"
        title="Scroll To Top"
      ></div>
      <div class="mobile-overlay" id="mobile-overlay"></div>
    </>
  );
}
export default ForgotPassword;
